html {
  font-size: 62.5%;
}

::-webkit-scrollbar {
  display: none;
}

#canvas-container {
  position: relative;
  height: 100vh;
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 45%;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 35%;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 25%;
  }
}

@media screen and (max-width: 479px) {
  html {
    font-size: 20%;
  }
}
